<template>

  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Merchant</label>
            <v-select
              v-model="merchantFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="merchantOptions"
              :clearable="false"
              class="w-100"
              :reduce="val => val.value"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>APP</label>
            <v-select
              v-model="appFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="appOptions"
              :clearable="false"
              class="w-100"
              :reduce="val => val.value"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Method</label>
            <v-select
              key="type_name"
              v-model="methodFilter"
              label="type_desc"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="methodOptions"
              :clearable="false"
              :searchable="false"
              class="w-100"
              :reduce="val => val.type_name"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :clearable="false"
              :searchable="false"
              class="w-100"
              :reduce="val => val.value"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Date Range</label>
            <date-picker
              v-model="dateRange"
              value-type="format"
              format="YYYY-MM-DD HH:mm:ss"
              type="datetime"
              range
              confirm
              :clearable="false"
              :shortcuts="shortcuts"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Search Mode</label>
            <v-select
              v-model="searchModeFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="searchModeOptions"
              :clearable="false"
              :searchable="false"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:searchModeFilter', val)"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Search Key</label>
            <b-form-input
              id="searchKeyInput"
              v-model="searchKey"
              placeholder="Key Word Here..."
              @keydown.enter.native="doSearch(1)"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label />
            <b-button
              class="mt-2"
              variant="primary"
              @click="doSearch(1)"
            >
              Search
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card no-body>
      <b-table
        class="position-relative"
        responsive
        :fields="transactionsTableFields"
        :items="transactions"
        primary-key="id"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <template #cell(trade_no)="data">
          <b-link
            :to="{ name: 'transactions-detail', query:{trade_no: data.item.trade_no, app_id: data.item.app_id , out_trade_no: data.item.out_trade_no , zone_id: tzSelected} }"
            target="_blank"
            class="font-weight-bold d-block"
          >
            {{ data.item.trade_no }}
          </b-link>
        </template>

        <template #cell(merchant)="data">
          {{ data.item.merchant_name }}
          <small class="text-muted">@{{ data.item.merchant_no }}</small>
        </template>

        <template #cell(app)="data">
          {{ data.item.app_name }}
          <small class="text-muted">@{{ data.item.app_id }}</small>
        </template>

        <template #cell(request_amount)="data">
          {{ data.item.request_currency }} {{ data.item.request_amount }}
        </template>

        <template #cell(create_time)="data">
          {{ data.item.create_time }}
        </template>

        <template #cell(update_time)="data">
          {{ data.item.update_time }}
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-overlay
      :show="loading"
      no-wrap
    />
  </div>
</template>

<script>
import {
  BCardHeader, BCardBody, BCard, BBadge, BRow, BCol, BLink, BTable, BPagination, BOverlay, BButton, VBModal, BFormInput,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import { showToast } from '@/libs/tool'
import useTransacitonsJs from './transactions'

const {
  fetchMerchants,
  fetchMerchantApps,
  fetchChannelFilters,
  searchRefundTransactions,
} = useTransacitonsJs()

export default {
  components: {
    vSelect,
    BCardHeader,
    BCardBody,
    BBadge,
    BCard,
    BRow,
    BCol,
    BLink,
    BTable,
    BPagination,
    BOverlay,
    BButton,
    BFormInput,
    DatePicker,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      loading: false,
      merchantFilter: '',
      merchantOptions: [{ label: 'All', value: '' }],
      appFilter: '',
      appOptions: [{ label: 'All', value: '' }],
      methodFilter: { type_id: '', type_name: '', type_desc: 'All' },
      methodOptions: [],
      statusFilter: '',
      statusOptions: [
        { label: 'All', value: '' },
        { label: 'OpenRefund', value: '22005' },
        { label: 'RefundProcessing', value: '22006' },
        { label: 'Refunded', value: '22000' },
      ],
      dateModeFilter: '1',
      searchModeFilter: '1',
      searchModeOptions: [
        { label: 'Transaction ID', value: '1' },
        { label: 'Merchant Reference', value: '2' },
      ],
      tzFilter: 'Etc/GMT+3',
      searchKey: '',
      dateRange: [],
      shortcuts: [
        {
          text: 'Today',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const end = moment().subtract(1, 'days').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 7 Days',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().subtract(6, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'This Month',
          onClick() {
            const end = moment().endOf('month').endOf('day')
              .toDate()
            const start = moment().startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last Month',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 3 Months',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(3, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
      ],
      searchRequest: {},
      transactions: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      transactionsTableFields: [
        { key: 'trade_no', label: 'TRANSACTION ID' },
        { key: 'out_request_no', label: 'REQUEST NO' },
        { key: 'out_trade_no', label: 'MERCHANT REFERENCE' },
        'merchant',
        'app',
        'source',
        { key: 'create_time', label: 'REQUEST TIME' },
        { key: 'request_amount', label: 'REQUEST AMOUNT' },
        'method',
        'status',
        { key: 'update_time', label: 'UPDATE TIME' },
      ],
      currentPage: 1,
      totalTransactions: 0,
      tzSelected: 'Etc/GMT+3',
    }
  },
  watch: {
    currentPage: function refetchSearchData(pageNum) {
      this.doSearch(pageNum)
    },
    dateRange: function formatWithTz(object) {
      const startTime = object[0]
      const endTime = object[1]
      const startTzTime = moment.tz(startTime, this.tzFilter)
      const endTzTime = moment.tz(endTime, this.tzFilter)
      const startTimeStr = startTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
      const endTimeStr = endTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')

      const dateArray = new Array(0)
      dateArray.push(startTimeStr, endTimeStr)
      this.$emit('update:dateRange', dateArray)
    },
    merchantFilter: function fillApps(merchantNo) {
      this.appOptions = [{ label: 'All', value: '' }]
      fetchMerchantApps(this, merchantNo, apps => {
        this.appOptions = apps
      })
    },
  },
  created() {
    fetchMerchants(this, merchants => {
      this.merchantOptions = merchants
    })
    fetchChannelFilters(this, filters => {
      this.methodOptions = filters.methods
      this.methodFilter = ''
    })

    this.dateRange = [moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
  },
  methods: {
    resolveStatusVariant(status) {
      switch (status) {
        case 'RefundProcessing':
        case 'OpenRefund':
          return 'primary'
        case 'Refunded':
        case 'PartialRefund':
          return 'danger'
        default:
          return 'secondary'
      }
    },
    doSearch(pageNum) {
      this.loading = true

      const [start, end] = this.dateRange
      const params = {
        merchant_no: this.merchantFilter,
        app_id: this.appFilter,
        method: this.methodFilter,
        status: this.statusFilter,
        start_time: start,
        end_time: end,

        date_mode: 1,
        search_mode: this.searchModeFilter,
        search_key: this.searchKey,
        page_num: pageNum,
        page_size: this.perPage,
      }

      this.searchRequest = params
      searchRefundTransactions(
        this.searchRequest,
        transactions => {
          this.transactions = transactions.list
          this.totalTransactions = transactions.total
          this.dataMeta.from = this.currentPage * this.perPage - (this.perPage - 1)
          this.dataMeta.to = this.totalTransactions - this.currentPage * this.perPage > 0 ? this.currentPage * this.perPage : this.totalTransactions
          this.dataMeta.of = this.totalTransactions

          this.loading = false
        },
        fail => {
          showToast(this, 'Warning', `Fetch refund order list with ${fail}.`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  height: 37px;
}
</style>
